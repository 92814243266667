var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("クレーム一覧")]), _c('v-row', {
    staticClass: "no-gutters mb-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "キーワード検索",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.formData.search,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "search", $$v);
      },
      expression: "formData.search"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "slot": "append",
      "color": "blue",
      "small": "",
      "elevation": "0",
      "height": "23"
    },
    on: {
      "click": _vm.submit
    },
    slot: "append"
  }, [_vm._v(" 検索 ")])], 1)], 1), _c('v-col', {
    staticClass: "cat_filter",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    staticClass: "form-text",
    attrs: {
      "outlined": "",
      "dense": "",
      "items": _vm.claimCategories,
      "hide-details": "auto",
      "placeholder": "クレームタグ管理",
      "chips": "",
      "multiple": "",
      "clearable": ""
    },
    on: {
      "change": _vm.submit
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            item = _ref.item,
            select = _ref.select;
        return [_c('v-chip', _vm._b({
          staticClass: "mb-1",
          attrs: {
            "small": "",
            "close": ""
          },
          on: {
            "click": select,
            "click:close": function clickClose($event) {
              return _vm.removeTag(item.value);
            }
          }
        }, 'v-chip', attrs, false), [_c('span', [_vm._v(_vm._s(item.text))])])];
      }
    }]),
    model: {
      value: _vm.formData.categories,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "categories", $$v);
      },
      expression: "formData.categories"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new",
    attrs: {
      "height": "30",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "100",
      "to": "/claim/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" クレームを登録する ")], 1)], 1)], 1), _c('v-row', {
    staticClass: "no-gutters mb-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "sub-title",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" 全" + _vm._s(_vm.claims.length) + "件 ")])], 1), _c('div', [_c('Table', {
    staticClass: "claim-list-tbl",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.claims,
      "total-records": _vm.pagination ? _vm.pagination.records_total : null,
      "number-of-pages": _vm.pagination ? _vm.pagination.total_pages : null,
      "items-per-page": _vm.itemsPerPage,
      "footer": false,
      "loading": _vm.loading,
      "options": _vm.options
    },
    on: {
      "edit-button": function editButton($event) {
        return _vm.detail(_vm.item);
      },
      "click:row": _vm.detail,
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.customer_site",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.customer ? [_c('span', [_vm._v(_vm._s(item.customer.name))]), item.site ? _c('span', [_vm._v(_vm._s(' / ' + item.site.name))]) : _vm._e()] : _vm._e()];
      }
    }, {
      key: "item.task_id",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.task_id ? [_vm._v(" " + _vm._s("".concat(item.task.id, "/").concat(item.task.date, "/").concat(item.task.service.name)) + " ")] : _vm._e()];
      }
    }, {
      key: "item.claim_category_id",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.categories ? _vm._l(item.categories, function (category, index) {
          return _c('v-chip', {
            key: index,
            staticClass: "mr-3 mb-2",
            attrs: {
              "color": "#8C5F5F",
              "text-color": "white",
              "label": "",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(category === null || category === void 0 ? void 0 : category.name) + " ")]);
        }) : _vm._e()];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.description.length > 60 ? item.description.substring(0, 60) + '...' : item.description) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "fab": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }