<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <h3 class="page-title mb-7 font-weight-bold">クレーム一覧</h3>
      <v-row class="no-gutters mb-6" align="center">
        <v-col cols="4">
          <v-text-field
            placeholder="キーワード検索"
            v-model="formData.search"
            dense
            outlined
            hide-details
          >
            <v-btn
              slot="append"
              color="blue"
              small
              class="white--text"
              elevation="0"
              height="23"
              @click="submit"
            >
              検索
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col cols="4" class="cat_filter">
          <v-select
            class="form-text"
            outlined
            dense
            :items="claimCategories"
            hide-details="auto"
            placeholder="クレームタグ管理"
            chips
            multiple
            clearable
            v-model="formData.categories"
            @change="submit"
          >
            <template v-slot:selection="{ attrs, item, select }">
              <v-chip
                class="mb-1"
                small
                close
                v-bind="attrs"
                @click="select"
                @click:close="removeTag(item.value)"
              >
                <span>{{ item.text }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>

        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/claim/create"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            クレームを登録する
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="no-gutters mb-6" align="center">
        <v-col cols="6" class="sub-title"> 全{{ claims.length }}件 </v-col>
      </v-row>
      <div>
        <Table
          class="claim-list-tbl"
          :headers="headers"
          :items="claims"
          :total-records="pagination ? pagination.records_total : null"
          :number-of-pages="pagination ? pagination.total_pages : null"
          :items-per-page="itemsPerPage"
          :footer="false"
          :loading="loading"
          @edit-button="detail(item)"
          @click:row="detail"
          :options.sync="options"
        >
          <template v-slot:item.customer_site="{ item }">
            <template v-if="item.customer">
              <span>{{ item.customer.name }}</span>
              <span v-if="item.site">{{ ' / ' + item.site.name }}</span>
            </template>
          </template>
          <template v-slot:item.task_id="{ item }">
            <template v-if="item.task_id">
              {{
                `${item.task.id}/${item.task.date}/${item.task.service.name}`
              }}
            </template>
          </template>
          <template v-slot:item.claim_category_id="{ item }">
            <template v-if="item.categories">
              <v-chip
                color="#8C5F5F"
                text-color="white"
                v-for="(category, index) in item.categories"
                :key="index"
                label
                small
                class="mr-3 mb-2"
              >
                {{ category?.name }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.description="{ item }">
            {{
              item.description.length > 60
                ? item.description.substring(0, 60) + '...'
                : item.description
            }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" fab x-small @click="edit(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </Table>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      loading: true,
      itemsPerPage: 50,
      headers: [
        { text: 'id', value: 'id' },
        { text: '顧客／物件', value: 'customer_site', sortable: true },
        { text: 'タスク', value: 'task_id', sortable: true },
        { text: '分類', value: 'claim_category_id', sortable: true },
        { text: '内容', value: 'description', sortable: true, width: '18%' },
        { text: '', value: 'actions', sortable: false }
      ],
      formData: {
        search: '',
        categories: []
      },
      options: {}
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['allClaimCategories']),
    claims() {
      return this.$store.getters.allClaims
    },
    pagination() {
      return this.$store.getters.ClaimPagination
    },
    claimCategories() {
      return this.$store.getters.allClaimCategories.map(ClaimCategories => {
        return { value: ClaimCategories.id, text: ClaimCategories.name }
      })
    }
  },
  methods: {
    // async getDataFromApi(data = {}) {
    //   this.loading = true
    //   let params = data
    //   params.paginate = 10
    //   await this.$store.dispatch('CLAIM_GET_ALL', params)
    //   this.loading = false

    //   await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL').then(() => {
    //     this.loading = false
    //   })
    // },
    getDataFromApi(data = {}) {
      this.loading = true
      let params = {
        search: data.search,
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        categories: data.categories
        // sortBy: this.options.sortBy[0],
        // sortDesc: this.options.sortDesc[0]
      }
      this.$store.dispatch('CLAIM_CATEGORY_GET_ALL')
      this.$store
        .dispatch('CLAIM_GET_ALL', params)
        .then(() => (this.loading = false))
        .finally(() => (this.loading = false))
    },
    submit() {
      this.getDataFromApi(this.formData)
    },
    edit(item) {
      //this.$router.push('/claim/edit/' + item.id)
      window.location.href = '/claim/edit/' + item.id
    },
    detail(item) {
      this.$router.push('/claim/detail/' + item.id)
    },
    removeTag(item) {
      this.formData.categories = this.formData.categories.filter(
        claimCategories => claimCategories !== item
      )
      this.submit()
    }
  }
}
</script>
<style src="./List.scss" lang="scss" scoped></style>